<template>
    <div class="playerContainer">
        <div
            class="burstPoint-content"
            v-if="burstPointTime > 0"
            :style="{ width: wavesurferWidth + 'px' }">
            <div class="structureTimeArr">
                <span
                    v-for="(item, index) in structureTimearr"
                    :key="index"
                    :style="{
                        width:
                            ((structureTimearr[index] - structureTimearr[index - 1]) /
                            audioDuration) *
                            wavesurferWidth - 2 + 'px',}">
                </span>
            </div>
            <div class="burstPoint-aboutTime">
                <p class="burstPoint-tip" v-if="burstPointTip">拖动修改高能点位置</p>
                <span
                    class="burstPoint-img"
                    @mouseover="changeBurstPointTipOver"
                    @mouseleave="burstPointTip = false"
                    :style="{ left: leftLocation - 11 + 'px' }"
                    v-drag>
                    <i class="iconfont icon-shixinhuomiao"></i> 
                </span>
                <br />
                <span
                    v-show="burstPointTimeTip & playOrNot2"
                    class="aboutTime-time1"
                    :style="{ left: leftLocation - 35 + 'px' }">
                    <i class="iconfont icon-shijian"></i>
                    {{ burstPointtime | delayDuration }}
                </span>
                <span
                    v-show="!burstPointTimeTip"
                    class="aboutTime-time2"
                    :style="{ left: leftLocation - 48 + 'px' }"
                    @click="createMusic">
                        <span id="animationn">
                            <i class="iconfont icon-zhongxin1"></i>
                        </span>
                        重新渲染
                </span>
            </div>
        </div>
        <div class="collectPlayer-container">
            <div class="player-leftContainer">
                <div class="playOrPause">
                    <i
                        v-if="playOrNot"
                        class="iconfont icon-bofang"
                        @click="playAudio"
                        >
                    </i>
                    <i
                        v-if="!playOrNot"
                        class="iconfont icon-liebiaozanting"
                        @click="playAudio"
                        >
                    </i>
                </div>
                <div class="audioVolume">
                    <div v-show="sliderShow" class="slider" style="height: 114px">
                        <a-slider vertical :default-value="50" @change="change" @afterChange="afterChange"/>
                    </div>
                    <span @click="showSlider">
                        <i class="iconfont icon-icon_vio_24">
                        </i>
                    </span>
                </div>
                <div class="downloadAudio">
                    <i class="iconfont icon-icon_playbar_download_24"></i>
                </div>
                <div class="audioInformation">
                    <div class="audioName">
                        {{musicName}}
                    </div>
                    <div class="playedTime">
                        {{playedTime | delayDuration}} / {{audioDuration | delayDuration}}
                    </div>
                </div>
            </div>
            <div class="player-rightContainer">
                <p class="timeLine">
                    <span
                        :style="{
                            left: (playedTime / audioDuration) * wavesurferWidth - 2 + 'px',
                        }"
                    ></span>
                </p>
                <div id="wavesurfer" @click="getPosition($event)"></div>
            </div>
        </div>
        
    </div>
</template>

<script>
import {
  POINT,
  FAVORITEORNOT,
  DOWNLOADFILE,
  downloadHost,
} from "../../src/api.js";
import WaveSurfer from "wavesurfer.js";
export default {
    props: {
        musicName: String,
        wavesurferAdr: String,
        burstPointTime: Number,
        musicId: Number,
        structureTimeArr: Array,
        burstConArr: Array, // 可移动范围
        structurePowerArr: Array,
        renderTip: Boolean, // 重复渲染的时候，渲染过程中不可点击
    },
    data() {
        return {
            burstPointtime: -1, // 父组件传递的高能点时间
            structureTimearr: [],
            leftLocation: -1, // 与高能点相关的位置
            burstPointTip: false, // 拖动修改高能点位置 是否显示
            burstPointTimeTip: true, // 播放器最下方提示
            saveBurstPointtime: -1,

            colorArr: ["#A2E0FB", "#68B6FE", "#4477FA", "#0039A8"], // 背景颜色数组

            playOrNot: false,
            sliderShow: false,
            playedTime: 0,
            audioDuration: 0,
            playOrNot2: false,
            wavesurferWidth: 0, // 波形图宽度
        }
    },
    directives: {
        drag(el, binding, vnode) {
            let dragBox = el;
            // vnode.context 是整个vue实例 相当于this
            let that = vnode.context;
            dragBox.onmousedown = (e) => {
                if (!that.renderTip) return;

                that.burstPointTimeTip = true;
                that.burstPointTip = false;
                //算出鼠标相对元素的位置
                let disX = e.clientX - dragBox.offsetLeft - 10;
                document.onmousemove = (e) => {
                    that.burstPointTip = false;
                    //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
                    that.leftLocation =
                        e.clientX - disX >
                        (that.wavesurferWidth * that.$props.burstConArr[1]) / that.audioDuration ? (that.wavesurferWidth * that.$props.burstConArr[1]) / that.audioDuration
                        : e.clientX - disX < (that.wavesurferWidth * that.$props.burstConArr[0]) / that.audioDuration ? (that.wavesurferWidth * that.$props.burstConArr[0]) / that.audioDuration
                        : e.clientX - disX;
                        // that.leftLocation =
                        //   e.clientX - disX > that.wavesurferWidth
                        //     ? that.wavesurferWidth
                        //     : e.clientX - disX < 0
                        //     ? 0
                        //     : e.clientX - disX;
                        //移动当前元素
                        // dragBox.style.left = left + "px";
                };
                document.onmouseup = (e) => {
                    that.burstPointTimeTip = false;
                    //鼠标弹起来的时候不再移动
                    document.onmousemove = null;
                    //预防鼠标弹起来后还会循环（即预防鼠标放上去的时候还会移动）
                    document.onmouseup = null;
                };
            };
        },
    },
    filters: {
        delayDuration (dur) {
            const duration = Math.round(dur)
            const min = Math.floor(duration / 60)
            const sec = (duration % 60) > 9 ? (duration % 60) : '0' + (duration % 60)
            return min + ':' + sec
        }
    },
    watch: {
        wavesurferAdr: function (val, oldVal) {
            this.getWidth();
            this.createWaveform();
            this.playedTime = 0;
        },
        leftLocation: function (val, oldVal) {
            this.burstPointtime = (val * this.audioDuration) / this.wavesurferWidth;
            if(Math.round(this.burstPointtime*100) == Math.round(this.$props.burstPointTime*100)) {
                this.burstPointTimeTip = true;
            }
        },
        structureTimeArr: {
            handler(val, oldVal) {
                this.structureTimearr = val;
                // this.setBackgroundColor();
            },
            deep: true,
        },
        burstPointTimeTip: function (val, oldVal) {
            if (!val) {
                let time = (this.audioDuration / this.wavesurferWidth) * 7;
                let moveTime = this.findNumber(
                    this.structureTimearr,
                    this.burstPointtime
                );
                if (Math.abs(moveTime - this.burstPointtime) <= time) {
                this.leftLocation =
                    (this.wavesurferWidth / this.audioDuration) * moveTime;
                }
                // if (this.saveBurstPointtime == this.BurstPointtime) {
                // }
            }
        },
    },
    beforeMount() {
        this.burstPointtime = this.$props.burstPointTime;
        this.saveBurstPointtime = this.burstPointtime
        this.structureTimearr = this.$props.structureTimeArr;
    },
    mounted() {
    },
    methods: {
        // 给音频设置音量
        change(value) {
            this.wavesurfer.setVolume(value / 100);
            window.event ? (window.event.cancelBubble = true) : e.stopPropagation();
        },
        // 音量设置结束，滑块消失
        afterChange() {
            this.sliderShow = false;
        },
        // 音量控制滑块是否显示
        showSlider() {
            if (!this.sliderShow) {
                this.sliderShow = true;
            } else if (this.sliderShow) {
                this.sliderShow = false;
            }
            window.event ? (window.event.cancelBubble = true) : e.stopPropagation();
        },
        // 获取宽度
        getWidth() {
            this.$nextTick(()=> {
                let wavesurfer = document.getElementById("wavesurfer");
                this.wavesurferWidth = wavesurfer.offsetWidth;
                this.leftLocation =
                (this.$props.burstPointTime / this.audioDuration) *
                this.wavesurferWidth;
            })
        },
        createWaveform() {
            this.$nextTick(() => {
                if (document.getElementsByTagName("wave").length === 0) {
                    this.wavesurfer = WaveSurfer.create({
                        container: "#wavesurfer",
                        waveColor: "#BCBCBC",
                        height: 43,
                        barWidth: 1,
                        progressColor: "#000000",
                        barGap: 2,
                        cursorColor: "rgba(0, 0, 0, 0.3)",
                    });
                }
                this.wavesurfer.load(downloadHost + this.$props.wavesurferAdr);

                this.wavesurfer.on("finish", () => {
                    this.playOrNot = false;
                });
                this.wavesurfer.on("ready", () => {
                    this.move();
                    this.playOrNot2 = true;
                    this.audioDuration = this.wavesurfer.getDuration();
                    this.structureTimearr.push(this.audioDuration);
                    this.setBackgroundColor();
                    this.getWidth();
                    this.wavesurfer.setVolume(0.5);
                });
                this.wavesurfer.on("audioprocess", () => {
                    this.playedTime = this.wavesurfer.getCurrentTime();
                });
            });
        },
        // 播放暂停音频
        playAudio() {
            if (!this.playOrNot2) {
                return;
            }
            this.wavesurfer.playPause();
            this.playOrNot = !this.playOrNot;

            this.$emit("playAudioTip", this.playOrNot);
        },
        // 点击波形图获取时间
        getPosition(e) {
            this.playedTime = (e.offsetX / this.wavesurferWidth) * this.audioDuration;
        },



        changeBurstPointTipOver() {
            this.burstPointTip = true;
            let styleLeft =
                document.getElementsByClassName("burstPoint-img")[0].style.left;
            let left = Number(styleLeft.substring(0, styleLeft.length - 2));
            this.$nextTick(() => {
                document.getElementsByClassName("burstPoint-tip")[0].style.left =
                left - 50 + "px";
            });
        },
        createMusic() {
            this.$emit("createAgain", this.burstPointtime);
            this.$props.renderTip = false;
            document.getElementById("animationn").className = "animationn";
        },
        setBackgroundColor() {
            this.$nextTick(() => {
                let parent =
                document.getElementsByClassName("structureTimeArr")[0].children;
                let setBackDomArr = [];
                for (let i = 0; i < parent.length; i++) {
                    if (i >= 1) {
                        setBackDomArr.push(parent[i]);
                    }
                }
                for (let j = 0; j < setBackDomArr.length; j++) {
                    setBackDomArr[j].style.background =
                        this.colorArr[this.$props.structurePowerArr[j] - 1];
                }
            });
        },
        destoryWave() {
            if (this.wavesurfer != null) {
                if(this.wavesurfer.isPlaying()) {
                    this.wavesurfer.playPause();
                    this.playOrNot = false
                    this.$emit("playAudioTip", this.playOrNot);
                } else {
                    return
                }
            }
        },
        findNumber(arr, target) {
            let result = arr[0];
            for (let i = 1, len = arr.length; i < len; i++) {
                if (Math.abs(arr[i] - target) < Math.abs(result - target)) {
                    result = arr[i];
                }
            }
            return result;
        },
        move() {
            this.$props.renderTip = true;
            if (document.getElementById("animationn") != null) {
                document.getElementById("animationn").className = "";
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.playerContainer{
    width: 100%;
    padding: 0px 110px;
    height: 93px;
    border: 1px solid rebeccapurple;
}
.collectPlayer-container{
    width: 100%;
    height: 93px;
    box-shadow: 0px -5px 41px rgba(10, 38, 81, 0.07);
    height: 93px;
    display: flex;     
    justify-content: space-between;
    position: relative;
    top: -2px;
    i{
        cursor: pointer;
    }
    .player-leftContainer{
        // border: 1px solid rebeccapurple;
        display: flex;
        .playOrPause{
            width: 106px;
            height: 46px;
            line-height: 46px;
            background: rgba(84, 144, 244, 0.07) !important;
            border-radius: 21px;
            margin-top: 18.5px;
            text-align: center;
            i{
                font-size: 24px;
            }
        }
        .audioVolume{
            margin-left: 12px;
            position: relative;
            // border: 1px saddlebrown solid;
            .slider {
                top: -95px;
                padding-bottom: 10px;
                position: absolute;
                margin-left: -3px;
                margin-top: 10px;
                background: #ffffff;
                box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
                border-radius: 5px;
            }
            span{
                margin-top: 29.5px;
                width: 24px;
                height: 24px;
                display: inline-block;
                // border: 1px saddlebrown solid;
                i{
                    font-size: 24px;
                    line-height: 24px;
                }
            }
        }
        .downloadAudio{
            margin-left: 12px;
            margin-top: 29.5px;
            width: 24px;
            height: 24px;
            // border: 1px saddlebrown solid;
            i{
                margin-top: 29.5px;
                font-size: 24px;
                line-height: 24px;
            }
        }
        .audioInformation{
            width: 8vw;
            margin-left: 12px;
            margin-top: 18.5px;
            min-width: 102.4px;
            .audioName{
                width: 8vw;
                min-width: 102.4px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                font-family: 'PingFang SC';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 128.5%;
                letter-spacing: 0.025em;
                color: #434343;
            }
            .playedTime{
                font-family: 'PingFang SC';
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                color: rgba(0, 0, 0, 0.4);
            }
        }
    }
    .player-rightContainer{
        width: 67%;
        // width: 770px;
        height: 43px;
        margin-top: 20px;
        // border: 1px solid rebeccapurple;
        // background: rgb(97, 58, 136);
        // border: #2FAA4A solid 1px;
        .timeLine {
            height: 93px;
            // background: red;
            margin-top: -20px;
            position: fixed;
            span {
                position: relative;
                display: inline-block;
                height: 93px !important;
                border: 1px solid rgba(0, 0, 0, 0.3);
            }
        }
        div{
            width: 100%;
        }
    }
}

.burstPoint-content {
    // border: 1px solid red;
    position: relative;
    float: right;
    height: 2px;
    z-index: 10;
    top: -2px;
    .structureTimeArr {
      position: absolute;
      margin-top: -11px;
      height: 2px;
      width: 100%;
      span {
        display: inline-block;
        height: 3px;
        margin-right: 2px;
        background: #456;
      }
      span:nth-child(1) {
        margin-right: -2px;
      }
    }
    .burstPoint-aboutTime {
      .burstPoint-tip {
        position: absolute;
        width: 124px;
        height: 24px;
        margin-top: 20px;
        display: inline-block;
        background: #000000;
        border-radius: 6px;
        font-family: PingFang SC;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 24px;
        text-align: center;
        color: #ffffff;
      }
      .burstPoint-img {
        cursor: pointer;
        z-index: 10 !important;
        display: inline-block;
        position: absolute;
        width: 22px;
        height: 22px;
        background: #ffeaeb;
        margin-top: -8px;
        border-radius: 100%;
        border: 3px solid rgba(255, 255, 255, 0.5);
        box-shadow: 0px 3px 4px rgba(154, 35, 35, 0.25);
        line-height: 18px;
        i {
          font-size: 12px;
          margin-left: 2px;
          color: #de1c1c;
        }
      }

      .aboutTime-time1 {
        position: relative;
        top: -60px;
        padding: 4px 10px;
        box-sizing: border-box;
        border-radius: 19px;
        font-family: PingFang SC;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #666666;
        color: #fff;
        background: #000000;
        i {
          font-size: 13px;
        }
      }
      .aboutTime-time2 {
        cursor: pointer;
        top: -60px;
        position: relative;
        padding: 3px 10px;
        background: #000000;
        border: 1px solid #000000;
        box-sizing: border-box;
        border-radius: 19px;
        font-family: PingFang SC;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #ffffff;
        i {
          font-size: 14px;
        }
        .animationn {
          display: inline-block;
          animation: ani-demo-spin 1.5s linear infinite;
        }
        @keyframes ani-demo-spin {
          from {
            transform: rotate(0deg);
          }
          50% {
            transform: rotate(180deg);
          }
          to {
            transform: rotate(360deg);
          }
        }
      }
    }
  }
</style>