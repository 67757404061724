<template>
  <div class="cont" id="cont"  @click="queryClick($event)">
    <div class="collection-download" 
      v-if="$store.state.userGrade != -1"
      :style="downLoadContainerShow ? 'display:block;' : 'display: none;'">
      <download-container
        ref="downloadCon"
        :wavesurferAdr="transferWavesurferAdr" 
        :musicId="transferMusicId"
        :musicName="transferMusicName"
        @closeDownloadContainer="closeDownloadContainer"
        @payAudioLoadingOver="payAudioLoadingOver"
        >
      </download-container>
    </div>
    <div class="paymentContainer" 
      :style="{top: scrollTop + 'px',display: paymentCenterShow ? 'block' :'none'}">
      <payment-center
        ref="paymentCont"
        :paymentForm="paymentForm"
        :musicName="transferMusicName"
        :needPaymentMusicId="transferMusicId"
        @closePaymentCenter="closePaymentCenter"
        @payAudioLoadingOver="payAudioLoadingOver"
        >
      </payment-center>
    </div>
    <div class="collection-container" id="collection-container">
      <div class="collection-title" id="collection-title">
        收藏
      </div>
      <div class="nologin" v-if="pageShowContent == 0">
        <div class="nologin-img">
          <img src="./../assets/collect/nologin.png"/>
        </div>
        <div class="nologin-explain">
          <div>请登录</div>
          <div>查看收藏歌曲哦...</div>
          <div @click="loginSystem">立即登录</div>
        </div>
      </div>
      <div class="nologin" v-if="pageShowContent == 1">
        <div class="nologin-img">
          <img src="./../assets/collect/nocollect.png"/>
        </div>
        <div class="nologin-explain">
          <div>您还没有</div>
          <div>收藏歌曲哦...</div>
        </div>
      </div>
      <div class="nologin"  v-if="pageShowContent == 3">
        <div class="nologin-img">
          <img src="./../assets/collect/loading.png"/>
          <img src="./../assets/collect/loading.gif"/>
        </div>
        <div class="nologin-explain1">
          <div>用力加载中...</div>
        </div>
      </div>
      <div v-if="pageShowContent == 2">
        <div class="search-container">
          <img src="../assets/collect/search.png"/>
          <input placeholder="搜索你的内容" 
            v-model="searchContent" 
            v-on:keyup="getSearchEnter($event)" 
            />
            <!-- @blur="getAudioArrBySearch" -->
        </div>
        <div class="collection-audioList">
          <div class="audioList-table">
            <div class="table-thead">
              <div class="table-thead-index"></div>
              <div class="table-thead-name">名称</div>
              <div class="table-thead-remarks">备注</div>
              <div class="table-thead-label">关键词</div>
              <div class="table-thead-date">
                生成日期
                <span @click="changeSort('date')">
                  <i class="iconfont icon-a-Vector388Stroke" 
                      :style="orderBy != '-createTime' ? 'color: #5490F4;' : ''">
                  </i>
                  <i class="iconfont icon-a-Vector389Stroke" 
                      :style="orderBy != 'createTime' ? 'color: #5490F4;' : ''">
                  </i>
                </span>
              </div>
              <div class="table-thead-duration">
                时长
                <span @click="changeSort('duration')">
                  <i class="iconfont icon-a-Vector388Stroke" 
                      :style="orderBy != '-duration' ? 'color: #5490F4;' : ''">
                  </i>
                  <i class="iconfont icon-a-Vector389Stroke" 
                      :style="orderBy != 'duration' ? 'color: #5490F4;' : ''">
                  </i>
                </span>
              </div>
              <div class="table-thead-buy">是否购买</div>
              <div class="table-thead-collDown">收藏/下载</div>
            </div>
            <div class="sortOrSearchLoading" v-if="sortOrSearchFlag">
              <div class="loadText">用力加载中...</div>
              <div class="sortLoadingImg">
                <img src="./../assets/collect/loading.png"/>
                <img src="./../assets/collect/loading.gif"/>
              </div>
            </div>
            <div class="nothingData" v-if="searchContent.length != 0 && audioArr.length == 0">搜索内容无对应收藏歌曲</div>
            <div class="table-tbody" v-if="!sortOrSearchFlag">
              <div class="table-tbody-tr"
                :style="playerId == item.musicId  ? 'background: #fff;' : ''"
                @click="shishiClick($event, index, item.musicId)"
                v-for="(item, index) in audioArr" :key="index">
                <div class="table-tbody-index">
                  <i v-if="playerId == item.musicId && !playOrNot" 
                    @click.stop="playAudio" 
                    class="iconfont icon-liebiaozanting">
                  </i>
                  <i v-if="playerId == item.musicId && playOrNot"
                    @click.stop="playAudio" class="iconfont icon-bofang">
                  </i>
                </div>
                <div class="table-tbody-name" 
                  :style="playerId == item.musicId  ? 'color: #2E7CF6;' : ''">
                  {{item.musicName}}
                </div>
                <div class="table-tbody-remarks">
                  <span class="remarks" 
                    v-if="item.remark != ''" 
                    @click.stop="inputRemarks($event,index)">
                    {{item.remark}}
                  </span>
                  <span class="noRemarks"
                     v-if="item.remark == ''" 
                    @click.stop="inputRemarks($event,index)">
                    点击编辑
                  </span>
                  <input class="inputRemarks" placeholder="请输入备注" ref="inputRemarks" 
                    v-model="item.remark"
                    v-on:keyup="getEnter($event, index)" 
                    @blur="changeRemarks(index)"/>
                </div>
                <div class="table-tbody-label">
                  <div v-if="item.use_type == 'label'">
                    <div class="choosedlabel-content" 
                      v-for="(itemm, indexx) in item.keywords"
                      :key="indexx"
                      >
                      {{itemm}}
                    </div>
                  </div>
                  <p class="label-text" 
                    v-if="item.use_type == 'text'">
                    {{item.keywords}}
                  </p>
                  <!-- v-for="(itemm, indexx) in item.label.slice(0,3)" -->
                </div>
                <div class="table-tbody-date">
                  {{item.create_time | delayCreateTime}}
                </div>
                <div class="table-tbody-duration">
                  {{item.musicTime | delayDuration}}
                </div>
                <div class="table-tbody-buy" :id="'buyHistory' + item.musicId">
                  <span v-if="item.is_buy">已购买</span>
                  <span v-if="!item.is_buy">未购买</span>
                </div>
                <div class="table-tbody-collDown" :id="'downloadCount' + item.musicId">
                  <span @click.stop="favoriteOrNot(0, item.musicId)" style="display:block;">
                    <i class="iconfont icon-icon_leftbar_collect_select_241" style="color: #d36666;"></i>
                  </span>
                  <span @click.stop="favoriteOrNot(1, item.musicId)" style="display:none;">
                    <i class="iconfont icon-icon_leftbar_collect_unselect_241"></i>
                  </span>
                  <span class="download" @click.stop="downloadFile($event, index)">
                    <i class="iconfont icon-icon_playbar_download_24"></i>
                  </span>
                  <span class="loading">
                    <img src="../assets/audioLoading.gif"/>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="collection-page" v-if="!sortOrSearchFlag && audioArr.length > 0">
            <v-container>
              <v-row justify="center">
                <v-col cols="8">
                  <v-container class="max-width">
                    <v-pagination
                    id="page-botton"
                      v-model="pageCurrent"
                      class="my-4"
                      circle
                      :length="pageTatal"
                    ></v-pagination>
                  </v-container>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </div>
      </div>
      <div class="collection-player" v-show="audioShow">
        <player 
          ref="player"
          :wavesurferAdr="playerWavesurferAdr" 
          :burstPointTime="burstPointTime"
          :musicId="playerId"
          :musicName="playerMusicName"
          :structureTimeArr="structureTimeArr"
          :burstConArr="burstConArr"
          :renderTip="true"
          :structurePowerArr="structurePowerArr"
          @createAgain="createAgain"
          @changePlayAudioFlag="changePlayAudioFlag"
          @setDownLoadContainerShow="setDownLoadContainerShow"
          @changeSliderShow="changeSliderShow"
          @collectMusicPlayerToCollect="favoriteOrNot"
          >
        </player>
        <!-- <collect-player
          ref="player2"
          :wavesurferAdr="wavesurferAdr"
          :musicName="musicName"
          :burstPointTime="burstPointTime"
          :musicId="musicId"
          :structureTimeArr="structureTimeArr"
          :burstConArr="burstConArr"
          :renderTip="true"
          :structurePowerArr="structurePowerArr"
          @createAgain="createAgain"
          @changePlayAudioFlag="changePlayAudioFlag"
          >
        </collect-player> -->
        <!-- <div style="height: 300px;"></div> -->
      </div>
    </div>
  </div>
  
</template>

<script>
import qs from 'qs';
import {send_record} from "../utils/umeng_util";
import {axiosGet, axiosPost} from "../../src/axios.js"
import { FAVORITEORNOT, FAVORITELIST, FILEINFO, SOUNDTRACK, UPDATEMUSICINFORMATION, DOWNLOADFILE} from "../../src/api.js"
import { data_process_new } from "../../src/utils/util.js";
import Player from "./player"
import CollectPlayer from "./collectionPlayer"
import DownloadContainer from './downloadContainer'
import PaymentCenter from './paymentCenter.vue'
export default {
  components: {
    Player,
    DownloadContainer,
    CollectPlayer,
    PaymentCenter
  },
  data:() => ({
    paymentForm: '',
    // homePayBecomeVip 开通会员
    // homePayBuyOnlyOne 单曲购买

    paymentCenterShow: false,
    downLoadContainerShow: false,
    volumeSliderShow: false,

    pageShowContent: -1, // 是否登录 0未登录 1登录未收藏 2正常显示 3正在加载中

    playOrNot: false, // 是否播放
    sortOrSearchFlag: false,
    searchContent: '',
    orderBy: '-createTime',

    audioArr:[], // 显示的音频数组
    audioArrList: [], // 音频数组列表
    pageCurrent: 1, // 当前页
    pageTatal: -1, // 总页数

    audioShow: false, // 播放器是否显示
    playerId: -1, // 正在播放的音频
    playerMusicName: '',
    playerWavesurferAdr: '', // 音频地址
    playerIsBuy: false,
    burstPointTime: -1,  // 高能点

    musicId: -1, // 音频ID

    structureTimeArr: [], // 结构时间点数组
    structurePowerArr: [], // 高能点能量数据
    burstConArr: [], // 高能点拖动范围控制
    beforePhraseTime: 0,
    localPhraseTime: 0,
    localburstPointTime: 0,
    reCalcData:{},  // 当前播放音频的重算数据
    
    transferMusicId: -1,
    transferWavesurferAdr: '', // 音频地址
    transferMusicName:'',

    timer: null,

    
    scrollTop:0
  }),
  watch: {
    audioArrList: {
      handler(val, oldVal) {
        if(val.length == 0){
          this.audioArr = []
          // 当前显示的收藏列表取消收藏最后一个显示无收藏列表，但是还有别的分页
          // 当前页歌曲都取消收藏 应该获取上一页的数据 && 当前页需要>1
          if(this.pageCurrent > 1) {
            this.pageCurrent = this.pageCurrent - 1
            this.getAudioArr(this.pageCurrent, false)
          }
        } else if(val.length < 8) {
          // 请求的数据长度<8 直接给audioArr赋值
          this.audioArr = val.slice(0, val.length)
        } else if(val.length == 8) {
          // 请求的数据==8 如果用户点击取消收藏
          // 需要将下一页的数据立马补齐当前页的8条数据
          // 所以需要拿到下一页的数据
          this.audioArr = val.slice(0, val.length)
          if(this.pageCurrent < this.pageTatal) {
            this.getAudioArr(this.pageCurrent+1, false);
          }
        } else if(val.length > 8) {
          // 请求的数据长度>8 直接给audioArr赋值
          this.audioArr = val.slice(0, 8)
        }
        this.$nextTick(()=> {
          let len = this.audioArr.length;
          for(let i = 0; i < len; i++){
            document.getElementsByClassName("table-tbody-collDown")[i].children[0].style.display = "block";
            document.getElementsByClassName("table-tbody-collDown")[i].children[1].style.display = "none";
          }
        })
        this.collectionSongNum()
        if(this.audioShow) {
          this.setDocumentHeight()
        }
      },
      deep: true,
      immediate: true
    },
    pageCurrent: function(val, oldVal) {
      this.getAudioArr(val, true);
    },
    paymentCenterShow: function(val, oldVal) {
        if(val) {
          send_record("pay_center_access", 
            {
              origin: window.location.pathname.slice(1),
              type: this.paymentForm == "homePayBecomeVip" ? 'buy_vip' : 'single_buy'
            }
          );
          document.documentElement.style.overflow = "hidden";
        } else {
          document.documentElement.style.overflow = "scroll";
        }
    },
  },
  filters: {
    delayCreateTime(createTime) {
      let date = new Date(Number(createTime * 1000))
      return date.getFullYear() + "/" + 
        ((date.getMonth() + 1) > 9 ? (date.getMonth() + 1)  : '0' + (date.getMonth() + 1) )  + "/" + 
        (date.getDate() > 9 ? date.getDate() : '0' + date.getDate() )
    },
    delayDuration (dur) {
      const duration = Math.round(dur)
      const min = Math.floor(duration / 60)
      const sec = (duration % 60) > 9 ? (duration % 60) : '0' + (duration % 60)
      return min + ':' + sec
    }
  },
  beforeMount() {
  },
  mounted() {
    window.addEventListener("scroll", this.getScrollTop, true)
  },
  activated(){
    this.judgePageShow()
  },
  methods:{
    getScrollTop() {
        this.scrollTop = document.documentElement.scrollTop;
    },
    queryClick(e) {
      if(!this.volumeSliderShow && !this.downLoadContainerShow){
        return;
      }
      let evt = event || window.event;
      let path = evt.composedPath && evt.composedPath();
      let showFlag;
      if(this.volumeSliderShow) {
        for(let i = 0; i < path.length; i++) {
          if(path[i].className.indexOf("slider") > -1){
            showFlag = true;
            break;
          } else if(path[i].className == ''){
            showFlag = false;
            break;
          }else {
            showFlag = false;
          }
        }
        this.volumeSliderShow = showFlag;
        if(!this.volumeSliderShow)
        this.$refs.player.closeSlider()
      } else if(this.downLoadContainerShow) {
        for(let i = 0; i < path.length; i++) {
          if(path[i].className.indexOf("collection-download") > -1){
            showFlag = true;
            break;
          } else if(path[i].className == ''){
            showFlag = false;
            break;
          }else {
            showFlag = false;
          }
        }
        this.downLoadContainerShow = showFlag;
      }
    },
    getSearchEnter(e) {
      if(this.timer != null) {
        clearTimeout(this.timer)
      } 
      this.timer = setTimeout(()=> {
        this.getAudioArrBySearch()
      }, 1000)
    },
    getAudioArrBySearch() {
      this.sortOrSearchFlag = true;
      this.pageCurrent = 1
      this.getAudioArr(this.pageCurrent, true)
    },
    inputRemarks(e, index) {
      let evt = event || window.event;
      let path = evt.composedPath && evt.composedPath();
      let dom = path[1].children;
      dom[0].style.display = "none";
      dom[1].style.display = "block";
      dom[1].focus()
    },
    getEnter(e,index) {
        if(e.key == "Enter") {
            this.changeRemarks(index)
        }
    },
    changeRemarks(index) {
      let dom = document.getElementsByClassName("table-tbody-remarks")[index].children;
      dom[1].blur();
      let data = {
        musicId: this.audioArr[index].musicId,
        remark: this.audioArr[index].remark
      }
      axiosPost(UPDATEMUSICINFORMATION,  qs.stringify(data))
        .then(res=> {
          console.log(res)
        }).catch(err => {
          console.log(err)
        })
    },
    changeSliderShow(val) {
      this.volumeSliderShow = val;
      if(this.volumeSliderShow) {
        this.downLoadContainerShow = false;
      }
    },
    setDownLoadContainerShow() {
      this.downLoadContainerShow = true;
      this.setIsBuy(this.playerIsBuy, this.playerId)

      this.transferMusicId = this.playerId;
      this.transferMusicName = this.playerMusicName
      this.transferWavesurferAdr = this.playerWavesurferAdr

      this.volumeSliderShow = false;
      this.$refs.player.closeSlider()
      this.$nextTick(()=> {
        let clickDom = document.getElementsByClassName("downLoadFile")[0]
          let dom = document.getElementsByClassName("collection-download")[0]
          dom.style.position = "fixed";
          dom.style.left = clickDom.getBoundingClientRect().left - 485 + 120 + 'px';
          dom.style.top = clickDom.getBoundingClientRect().top - 196 + 5 + 'px';
      })
    },
    // 下载音频
    downloadFile (e,index) {
        this.downLoadContainerShow = true;
        this.volumeSliderShow = false;
        if(!this.volumeSliderShow)
        this.$refs.player.closeSlider()
        
        this.transferWavesurferAdr = this.audioArr[index].musicDemoFilePath;
        this.transferMusicName = this.audioArr[index].musicName;
        this.transferMusicId = this.audioArr[index].musicId;

        this.setIsBuy(this.audioArr[index].is_buy, this.transferMusicId);
        this.$nextTick(()=> {
          let dom = document.getElementsByClassName("collection-download")[0]
          dom.style.position = "absolute";
          dom.style.left = e.target.getBoundingClientRect().left - 365 - 3 + 'px'
          dom.style.top = e.target.getBoundingClientRect().top - 196 + document.documentElement.scrollTop + 'px'
        })
    },
    payAudioLoadingOver(loadingOverId) {
      let dom
      if(loadingOverId == -100) {
        dom = document.getElementById("downloadCount" + this.transferMusicId).children;
        dom[2].style.display = "block";
        dom[3].style.display = "none";
        this.$refs.player.changeDownloadORLoading(this.transferMusicId, true);
      }
      else {
        dom = document.getElementById("downloadCount" + loadingOverId).children;
        dom[2].style.display = "block";
        dom[3].style.display = "none";
        this.$refs.player.changeDownloadORLoading(loadingOverId, true);
      }
      
    },
    closeDownloadContainer(paymentSource) {
      this.paymentForm = '';
      this.downLoadContainerShow = false;
      if(typeof(paymentSource) == 'string') {
        this.paymentForm = "home" + paymentSource;
        this.paymentCenterShow = true;
      } else {
        let dom
          document.getElementById('buyHistory'+ paymentSource).children[0].innerText = '已购买';
          dom = document.getElementById("downloadCount" + paymentSource).children;
          dom[2].style.display = "none";
          dom[3].style.display = "block";
          if(this.playerId != -1) {
            this.$refs.player.changeDownloadORLoading(this.playerId, false);
          }
      }
    },
    closePaymentCenter(id) {
      this.paymentCenterShow = false;
      let dom = document.getElementById("downloadCount" + this.transferMusicId).children;
      dom[2].style.display = "none";
      dom[3].style.display = "block";
      if(id != -100) {
        document.getElementById('buyHistory'+ id).children[0].innerText = '已购买';
      }
      if(this.playerId != -1) {
        this.$refs.player.changeDownloadORLoading(this.transferMusicId, false);
      }
    },
    favoriteOrNot(flag, musicId) {
      let dom = document.getElementById("downloadCount" + musicId).children
      if(flag){
        dom[0].style.display = "block";
        dom[1].style.display = "none";
      }else {
        dom[0].style.display = "none";
        dom[1].style.display = "block";
      }
      // flag 0 取消  1收藏
      this.$refs.player.changeCollectionTip(musicId, flag);
      let data = {
          musicId: musicId,
          status: flag
      }
      axiosPost(FAVORITEORNOT,  qs.stringify(data), {
          headers: {
          'content-type': 'application/x-www-form-urlencoded',
          'token': localStorage.getItem("token")
          }
      }).then(res => {
        flag == 0 
        ? send_record("collect_cancel_forever", {origin: "collection_list"}) 
        : send_record("collect_forever", {origin: "collection_list"});
        console.log(flag == 0 ? "取消收藏成功" : "收藏成功")
        let cancelFavoriteList = localStorage.getItem("cancelFavoriteList");
        if(flag == 0) {
            localStorage.setItem("cancelFavoriteList", cancelFavoriteList + ',' + musicId);
        } else {
            let arr = cancelFavoriteList.split(",");
            let index = arr.findIndex(items => items == musicId);
            if(index > -1) {
                arr.splice(index, 1);
            }
            localStorage.setItem("cancelFavoriteList", arr.join(','));
        }
      }).catch(err => {
        console.log(flag == 0 ? "取消收藏失败" : "收藏失败")
        this.$message.error("取消收藏失败,请再试一次");
      })

    },
    collectionSongNum(){
      if(this.audioArr.length == 0 && this.searchContent.length == 0) {
        this.pageShowContent = 1;
      } else {
        this.pageShowContent = 2;
      }
    },
    judgePageShow() {
      // 是否登录 0未登录 1登录未收藏 2正常显示 3正在加载中
      if(localStorage.getItem("token") == null) {
        this.pageShowContent = 0;
      }  else {
        this.pageShowContent = 3;
        this.getAudioArr(this.pageCurrent, true);
      }
    },
    changePlayAudioFlag(tip) {
      this.playOrNot = tip;
    },
    playAudio() {
      this.$refs.player.playAudio();
    },
    destoryWave() {
      this.$refs.player.destoryWave();
    },
    shishiClick(e, index, musicId) {
      if(e.target.className.indexOf("inputRemarks") > -1)
      return
      this.playOrNot = false;
      this.playerMusicName = this.audioArr[index].musicName

      this.$refs.player.changeCollectionTipTrue()


      this.structureTimeArr = []
      this.reCalcData = this.audioArr[index].musicCalcParam
      this.burstPointTime = this.reCalcData.BurstTime
      this.localPhraseTime = ((60 / this.reCalcData._bpm) * this.reCalcData._beat_per_phrase)*this.reCalcData.offsetSpeed;
      let phrases = this.reCalcData.phrases
      let phrases_split
      for (let i = 1; i < phrases.length; i++) {
        phrases_split = phrases[i].split("_")
        this.structureTimeArr.push(this.localPhraseTime * (i-1))
        if (phrases_split.length==3) {
          this.structurePowerArr.push(phrases_split[1][0]) // 取能量级的第一位
        }
      }
      this.burstConArr=[this.localPhraseTime*0.6,this.reCalcData.duration-this.localPhraseTime]
      this.burstPointTime = this.reCalcData.BurstTime
      this.$refs.player.playAudio();
      if(this.playerId != musicId) {
        this.playOrNot = false;
      }
      this.playerId = musicId;
      if(!this.audioShow){
        this.audioShow = true;
      }
      this.setDocumentHeight()
      this.playerWavesurferAdr = this.audioArr[index].musicDemoFilePath;
      this.playerId = musicId

      this.playerIsBuy = this.audioArr[index].is_buy
    },
    changeSort(str) {
        if(str == 'date') {
            if(this.orderBy == 'createTime' || this.orderBy == 'duration' || this.orderBy == '-duration') {
                this.orderBy = '-createTime';
            }else {
                this.orderBy = "createTime";
            }
        }else if(str == 'duration') {
            if(this.orderBy == 'duration' || this.orderBy == 'createTime' || this.orderBy == '-createTime') {
                this.orderBy = '-duration';
            }else {
                this.orderBy = "duration";
            }
        }
        this.sortOrSearchFlag = true;
        this.getAudioArr(this.pageCurrent, true)
    },
    getAudioArr(pageCurrent, flag) {
      // flag ==0 audioArrList不用清空   ==1 audioArrList清空
      let data = {
        "page": pageCurrent,
        "size": 8,
        "search": this.searchContent,
        "orderBy": this.orderBy
      }
      axiosGet(FAVORITELIST,data)
        .then(res => {
        if(flag) {
          this.audioArrList = []
        }
        if(this.sortOrSearchFlag) {
          this.sortOrSearchFlag = false;
        }
        this.pageTatal = Math.ceil(res.data.total / 8)
        this.audioArrList = this.audioArrList.concat(res.data.favorite_list);
      }).catch(err => {
        let text
        if(err.data.code == 0) {
          text = err.data.msg;
        } else {
          text = "获取收藏列表失败,请再试一次";
        }
        this.$message.error(text);
      })
    },
    loginSystem() {
      this.$emit("collectionLoginSystm");
    },
    // 重新生成音乐
    createAgain(time) {
      if(localStorage.getItem("collectionBeforeDownloadCount") == null) {
        localStorage.setItem("collectionBeforeDownloadCount", 1);
      } else {
        localStorage.setItem("collectionBeforeDownloadCount", Number(localStorage.getItem("collectionBeforeDownloadCount")) + 1);
      }
      this.beforePhraseTime = (60 / this.reCalcData._bpm) * this.reCalcData._beat_per_phrase;
      axiosPost(FILEINFO, qs.stringify({fid:this.reCalcData._tid}, { indices: false })).then((res) => {
        let localBurstIndex = this.reCalcData.BurstIndex
        let offsetPhrase = Math.round(time / this.beforePhraseTime) - 1 -  localBurstIndex  // localBurstIndex是从0开始的，前面是从1开始的，需要对前面-1
        let localPhraseBeforeBurst = localBurstIndex + offsetPhrase
        let offsetSpeed = (this.reCalcData.BurstTime+offsetPhrase * this.beforePhraseTime)/time
        let duration = this.reCalcData.duration
        let this_data = {
          offsetSpeed: offsetSpeed,
          localLabelId: this.reCalcData._tid,
          localBurstArr: this.reCalcData.BurstArr,
          localFirstBurst: false,
          showBurstPoint: true,
          localBurstIndex: localBurstIndex,
          localPhraseTime: this.localPhraseTime,
          beforePhraseTime: this.beforePhraseTime,
          localPhraseBeforeBurst: localPhraseBeforeBurst,
        };
        this.createMusicWithBurstPoint(duration, res, this_data)
       })
    },
    createMusicWithBurstPoint(duration, res, in_this_data){
      try {
        let datas = data_process_new(duration, res, in_this_data);
        let that_data = datas["this_data"];
        Object.keys(that_data).forEach((key) => {
          this[key] = that_data[key];
        });
        this.getAudio_new(datas["datas"]);
      } catch (err) {
        this.buttonState = true;
        this.$message.error(err.message);
      }
    },
    getAudio_new(datas) {
      datas['offset_speed'] = this.offsetSpeed
      let phrases = datas['phrases']
      axiosPost(SOUNDTRACK,  
        qs.stringify(datas, { indices: false })
      ).then(res=> {
        this.audioShow = true;
        this.structureTimeArr=[]
        this.structurePowerArr=[]
        this.playerWavesurferAdr = res.data.path
        this.playerId = res.data.musicId
        this.$refs.player.changeCollectionTipFalse()
        let phrases_split
        for (let i = 1; i < phrases.length; i++) {
          phrases_split = phrases[i].split("_")
          this.structureTimeArr.push(this.localPhraseTime * (i-1))
          this.structurePowerArr.push(phrases_split[1][0]) // 取能量级的第一位
        }
        this.burstConArr=[this.localPhraseTime*0.51,datas.duration*datas.offset_speed-this.localPhraseTime]
        this.burstPointTime = (this.localPhraseBeforeBurst+1) * this.localPhraseTime
      })
    },
    setDocumentHeight() {
      if(this.audioArr.length >= 7) {
        document.getElementById("collection-container").style.height = "1050px"
        window.scrollTo(0, 1050 - window.innerHeight);
      } else {
        document.getElementById("collection-container").style.height = "100vh";
        document.getElementById("collection-container").style.minHeight = "900px";
      }
    },
    setIsBuy(flag, id) {
      if(!flag) {
        let str = localStorage.getItem("downloadMusicID");
        if(str == null) {
          this.$refs.downloadCon.setIsBuy(false);
        } else {
          let arr = str.split(",");
          for(let i = 0; i < arr.length; i++) {
            if(id == arr[i]) {
              this.$refs.downloadCon.setIsBuy(true);
              break;
            }else {
              this.$refs.downloadCon.setIsBuy(false);
            }
          }
        }
      } else {
        this.$refs.downloadCon.setIsBuy(true);
      }
    }
  }
}
</script>


<style lang="scss" scoped>
.paymentContainer{
    position: absolute;
    width: 100vw;
    min-width: 1440px;
    height: 100vh;
    min-height: 700px;
}
.cont{
  height: 100vh;
  min-height: 900px;
}
.collection-download{
  z-index: 2;
  width: 485px;
  height: 196px;
}
.collection-container{
  margin-left: 88px !important;
  user-select:none;
  width: calc(100vw - 88px);
  min-width: 1352px !important;
  min-height: 900px;
  height: 100vh;
  padding-left: 68px !important;
  padding-right: 113px !important;
  background: #f3f3f3;
  .collection-title{
    font-family: PangMenZhengDao !important;
    padding-top: 80px;
    font-family: PangMenZhengDao;
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    line-height: 134.5%;
    letter-spacing: 0.025em;
    color: #000000;
  }
  .nologin{
    display: flex;
    margin-top: 140px;
    .nologin-img{
      margin-left: 260px;
      width: 274px;
      height: 352px;
      img:nth-child(1){
        width: 274px;
        height: 352px;
      }
      img:nth-child(2) {
        width: 36px;
        height: 36px;
        margin-top: -370px;
        margin-left: 120px;
      }
    }
    .nologin-explain{
      margin-top: 108px;
      margin-left: 84px;
      font-family: PingFang SC;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 38px;
      letter-spacing: 1.3px;
      color: #B8B8B8;
      div:nth-child(3){
        cursor: pointer;
        margin-top: 35px;
        font-size: 14px;
        color: #000000;
        border: 1px solid #000000;
        box-sizing: border-box;
        border-radius: 24px;
        width: 120px;
        height: 34px;
        text-align: center;
        line-height: 34px;
      }
    }
    .nologin-explain1{
      margin-top: 128px;
      margin-left: 63px;
      font-family: PingFang SC;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 38px;
      letter-spacing: 1.3px;
      color: #B8B8B8;
    }
  }
  .search-container{
    margin-top: 50px;
    margin-bottom: 16px;
    width: calc(100vw - 268px);
    min-width: 1172px;
    height: 54px;
    background: rgba(0, 0, 0, 0.06);
    border-radius: 46px;
    line-height: 44px;
    padding: 5px 24px;
    img{
      margin-right: 18px;
      margin-top: -7px;
      width: 23px;
    }
    input{
      width: calc(100vw - 358px);
      height: 44px;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.8);
      border: none;
      outline: none;
    }
    input::-webkit-input-placeholder {
      color: rgba(0, 0, 0, 0.4);
    }
  }
  .collection-audioList{
    width: calc(100vw - 268px);
    min-width: 1172px;
    margin-top: 25px;
    .audioList-table{
      .table-thead{
        border-bottom: 1px solid #b8b8b8;
        font-family: PingFang SC;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 69px;
        letter-spacing: 0.025em;
        height: 73px;
        color: rgba(0, 0, 0, 0.4);
        display: flex;
        justify-content: space-between;
        padding: 0 40px;
        text-align: center;
        .table-thead-index{
          width: 24px;
          // background: #C56B6B;
        }
        .table-thead-name{
          min-width: 150px;
          text-align: left;
          width: calc((150 / 1172) * (100vw - 268px));
          // background: #578887;
        }
        .table-thead-remarks{
          min-width: 145px;
          width: calc((145 / 1172) * (100vw - 268px));
          // background: #64D1CF;
        }
        .table-thead-label{
          min-width: 310px;
          width: calc((310 / 1172) * (100vw - 268px));
          // background: #276968;
        }
        .table-thead-date{
          min-width: 126px;
          width: calc((126 / 1172) * (100vw - 268px));
          // background: #53B3B1;
          span{
            cursor: pointer;
            margin-left: -6px;
            i{
              font-size: 14px;
            }
            i:nth-child(2) {
              margin-left: -6px;
            }
          }
        }
        .table-thead-duration{
          width: 62px;
          // background: #53B3B1;
          span{
            cursor: pointer;
            margin-left: -6px;
            i{
              font-size: 14px;
            }
            i:nth-child(2) {
              margin-left: -6px;
            }
          }
        }
        .table-thead-buy{
          width: 82px;
          // background: palegreen;
        }
        .table-thead-collDown{
          width:  82px;
          // background: #DE5555;
        }
      }
      .sortOrSearchLoading{
        margin: 30px auto 0;
        text-align: center;
        width: 274px;
        overflow: hidden;
        .loadText{
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 38px;
          letter-spacing: 1.3px;
          color: #B8B8B8;
        }
        .sortLoadingImg{
          width: 274px;
          height: 352px;
          img:nth-child(1){
            width: 274px;
            height: 352px;
          }
          img:nth-child(2) {
            width: 36px;
            height: 36px;
            margin-top: -370px;
          }
        }
      }
      .nothingData{
        font-family: PingFang SC;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        letter-spacing: 0.025em;
        text-align: center;
        color: #999999;
        margin-top: 45px;
      }
      .table-tbody{
        margin-top: 4px; 
        .table-tbody-tr{
          display: flex;
          justify-content: space-between;
          height: 60px;
          border-radius: 3px;
          line-height: 60px;
          padding: 0 40px;
          text-align: center;
          .table-tbody-index{
            width: 24px;
            // background: #C56B6B;
            i{
              font-size: 26px;
              color: #5490F4;
            }
          }
          .table-tbody-name{
            min-width: 150px;
            width: calc((150 / 1172) * (100vw - 268px));
            // background: #578887;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            letter-spacing: 0.025em;
            text-align: left;
            color: rgba(0, 0, 0, 0.8);
          }
          .table-tbody-remarks{
            min-width: 145px;
            width: calc((145 / 1172) * (100vw - 268px));
            // background: #64D1CF;
            .remarks{
              display: inline-block;
              width: 100%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              font-family: 'PingFang SC';
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              text-align: center;
              letter-spacing: 0.025em;
              color: rgba(0, 0, 0, 0.8);
            }
            .noRemarks{
              min-width: 145px;
              width: calc((145 / 1172) * (100vw - 268px));
              height: 60px;
              margin-top: -20px;
              display: inline-block;
              font-family: 'PingFang SC';
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              text-align: center;
              letter-spacing: 0.025em;
              color: rgba(0, 0, 0, 0.3);
            }
            input{
              display:none;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              font-family: 'PingFang SC';
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              text-align: center;
              letter-spacing: 0.025em;
              color: rgba(0, 0, 0, 0.8);
              border: none;
              outline: none;
            }
          }
          .table-tbody-label{
            min-width: 310px;
            width: calc((310 / 1172) * (100vw - 268px));
            // background: #276968;
            >div{
              justify-content: center;
              display: flex;
            }
            .choosedlabel-content{
              margin-top: 16px;
              border: 1px solid rgba(0, 0, 0, 0.16);
              box-sizing: border-box;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              border-radius: 12px;
              height: 24px;
              line-height: 24px;
              font-family: PingFang SC;
              font-style: normal;
              font-weight: normal;
              font-size: 12px;
              line-height: 131%;
              display: flex;
              align-items: center;
              letter-spacing: 0.025em;
              color: #666666;
              padding:0 10px;
            }
            .choosedlabel-content:nth-child(2){
              margin: 16px 4px;
            }
            .label-text{
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              font-family: 'PingFang SC';
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              text-align: center;
              letter-spacing: 0.025em;
              color: rgba(0, 0, 0, 0.8);
            }
          }
          .table-tbody-date{
            min-width: 126px;
            width: calc((126 / 1172) * (100vw - 268px));
            // background: #53B3B1;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-family: 'PingFang SC';
          }
          .table-tbody-duration{
            width: 62px;
            // background: #53B3B1;
          }
          .table-tbody-buy{
            width: 82px;
            // background: palegreen;
          }
          .table-tbody-collDown{
            width:  82px;
            padding: 0px 7px;
            display: flex;
            justify-content: space-around;
            // background: #000000;
            span{
              cursor: pointer;
              i{
                font-size: 20px;
              }
            }
            span:nth-child(2){
              display: none;
            }
            .download{
              color: #979797;
            }

            span:nth-child(4) {
              cursor: default;
              display: none;
              img{
                width: 45px;
              }
            }
          }
        }
        .table-tbody-tr:hover{
          cursor: pointer;
          background: #fff;
        }
      }
    }
    .collection-page{
      height: 36px;
      margin-top: 20px;
      >.container{
        margin-top: -45px;
        .container{
          padding-top: 12px !important;
          padding-bottom: 0 !important;
        }
      }
    }
  }
  
  .collection-player{
    position: fixed;
    width: calc(100vw - 268px);
    min-width: 1170px;
    height: 80px;
    bottom: 60px;
    background: #FFFFFF;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
  }
}
</style>

<style>
 /* v-data-table--has-bottom theme--light */
.v-sheet{
  box-shadow: none !important;
}
.v-data-table{
  background: #f9f9f9 !important; 
}
.v-data-footer{
  display:none !important;
}
</style>

<style>
.collection-page .v-pagination__item{
  background: #f9f9f9 !important;
  box-shadow: none !important;
}
.collection-page .v-pagination__navigation{
  background: #f9f9f9 !important;
  box-shadow: none !important;
}
.collection-page #page-botton .primary{
  background: #000 !important;
}
.collection-page #page-botton li{
  box-shadow: none !important;
}
</style>

<style>
/* 反馈选项框 */
.select-control .v-input__slot{
  border-radius: 0 !important;
  width: 66px !important;
  height: 18px !important;
  min-height: 30px !important;
  padding:  0px !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3) !important;
  background: #f9f9f9 !important;
  box-shadow: none !important;
}


.select-control label{
  font-size: 12px !important;
}
.select-control .v-select__selections {
  font-size: 16px !important;
  color: #5490F4 !important;
}

/* .genreConStyle .v-select__selections{ color: #6273BD !important;}
.moodConStyle .v-select__selections{ color: #E0694E !important;}
.sceneConStyle .v-select__selections{ color: #578887  !important;} */
.v-menu__content {
  box-shadow: none !important;
}
.v-menu__content .v-list{
  padding: 0 !important;
  text-align: center !important;
}

#list-35 .primary--text{
  color: #f3f3f3 !important;
}

.v-list .v-list-item--active{
  background:  #f3f3f3  !important;
}

.v-list-item--active .v-list-item__content{
  color: #000 !important;
}
</style>